<template>
  <v-card
    v-if="layout && layout.resolution"
    flat
    width="100%"
    height="100%"
    max-width="550"
    min-height="80vh"
    class="d-flex flex-column pa-0"
  >
    <v-alert
      v-if="error"
      dense
      outlined
      border="left"
      type="error"
      class="mb-0"
      dismissable
    >
      {{ error.message }}
    </v-alert>
    <m-layout-grid
      ref="gridEditor"
      v-model="newGrid"
      class="flex-grow-1"
      :layout-widgets="layout.widgets"
      :aspect-ratio="layout.resolution | aspectRatio"
      editor
      @new-grid="updateGrid"
      @item-edit="configureLayoutWidgetId = $event"
      @item-remove="removeWidget"
    />
    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        outlined
        color="primary"
        :disabled="layout.widgets.length >= 5"
        @click="addWidget = true"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        Add Widget
      </v-btn>
    </v-card-actions>
    <v-card-actions class="flex-wrap">
      <v-btn
        color="primary"
        icon
        @click="distributeVertically"
      >
        <v-icon>
          mdi-arrow-split-horizontal
        </v-icon>
      </v-btn>
      <v-btn
        color="primary"
        icon
        @click="distributeHorizontally"
      >
        <v-icon>
          mdi-arrow-split-vertical
        </v-icon>
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="newGrid"
        color="grey"
        outlined
        @click="undo"
      >
        Undo
      </v-btn>
      <v-btn
        color="primary"
        outlined
        :loading="saving"
        :disabled="!newGrid || saving"
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
    <m-component-adder
      v-model="addWidget"
      :layout-id="layout.id"
      component-type="widget"
      @created="configureLayoutWidgetId = $event"
    />
    <m-component-configurator
      :layout-item-id="configureLayoutWidgetId"
      component-type="widget"
      :layout-id="layout.id"
      @done="configureLayoutWidgetId = ''"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import MComponentConfigurator from '@backend/components/MComponentConfigurator'
import MComponentAdder from '@backend/components/MComponentAdder'
import MLayoutGrid from '@/components/core/MLayoutGrid'
export default {
  components: {
    MComponentConfigurator,
    MComponentAdder,
    MLayoutGrid
  },
  data: () => ({
    error: null,
    addWidget: false,
    addingWidgetId: null,
    configureLayoutWidgetId: '',
    newGrid: null,
    saving: false,
    removingWidget: false,
    touchDevice: 'ontouchstart' in document.documentElement
  }),
  computed: {
    ...mapGetters(['layout'])
  },
  methods: {
    updateGrid(newGrid) {
      let gridChanged = false
      if (newGrid) {
        newGrid.forEach(({ layoutWidgetId, grid }) => {
          const layoutWidget = this.layout.widgets.find(x => x.id === layoutWidgetId)
          const prevGrid = layoutWidget.grid || {}
          Object.keys(grid).forEach((key) => {
            if (grid[key] !== prevGrid[key]) {
              gridChanged = true
            }
          })
        })
      }
      this.newGrid = gridChanged ? newGrid : null
    },
    async save() {
      this.saving = true
      try {
        await Promise.all(this.newGrid.map(({ layoutWidgetId, grid }) => {
          return this.$db.collection('layout-widgets').doc(layoutWidgetId).update({ grid })
        }))
      } catch (error) {
        this.error = error
      }
      this.saving = false
    },
    undo() {
      this.$refs.gridEditor.initializeGrid()
    },
    async removeWidget(layoutWidgetId) {
      if (confirm('Are you sure you want to delete this item?')) {
        this.removingWidget = true
        try {
          await this.$db.collection('layout-widgets').doc(layoutWidgetId).delete()
        } catch (error) {
          this.error = error
        }
        this.removingWidget = null
      }
    },
    distributeHorizontally() {
      this.$refs.gridEditor.distributeHorizontally()
    },
    distributeVertically() {
      this.$refs.gridEditor.distributeVertically()
    }
  }
}
</script>
